import { CallBackFormData } from 'components';
import { getApiInstance } from './getApiInstance';

export interface OpeningHoursProps {
	close: string;
	open: string;
}
export type RequestState = 'success' | 'error' | 'info';
const alreadyRegisteredNumber = '116';
const api = getApiInstance();

export const getOpeningHours = async (days: number): Promise<OpeningHoursProps[]> => {
	const startTime = new Date();
	if (startTime.getHours() > 8) startTime.setHours(startTime.getHours() + 1);

	try {
		const getOpeningHoursApi = await api.post(`/api/v1/callmeback/OpeningHours`, {
			from: startTime.toISOString(),
			days: days.toString(),
		});
		const data = await getOpeningHoursApi.data;
		return data;
	} catch (error) {
		throw new Error(error);
	}
};

export const registerCallBack = async (
	formData: CallBackFormData,
	selectedDate: OpeningHoursProps,
): Promise<RequestState> => {
	try {
		// We generate the time options based on the selectedDate.Open.
		// Then the selectedTimeIndex will correlate with added hours for each of the options based on the opening time on the selected date.

		const startTime = new Date(selectedDate.open);

		startTime.setHours(/*startTime.getHours() +*/ parseInt(formData.selectedTimeIndex));

		const registerCallApi = await api.post(
			`/api/v1/callmeback/BookMeeting`,
			// JSON.stringify({
			{
				customerNumber: formData.customerNumber,
				customerName: formData.name,
				dateTimeStart: startTime.toISOString(),
				customerPhoneNumber: formData.phoneNumber,
				bankAdvisorEmail: formData.bankAdvisorEmail,
				callSubject: formData.selectedCallSubject,
				bankAndBranch: formData.selectedBank + ' - ' + formData.selectedBankBranch,
			},
			// }),
		);
		const data = await registerCallApi.data;
		if (data?.resultCode === 'error') return 'error';
		if (data?.resultCode == alreadyRegisteredNumber) return 'info';
		return 'success';
	} catch (error) {
		throw new Error(error);
	}
};
